@import 'functions';
@import 'variable';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

html {
    height: 100%;
    color: $siteTextColor;
    background: #121212;
    body {
        box-sizing: border-box;
        height: 100%;
        font-size: rem(1.6);
        margin: 0;
        color: #000000;
        font-weight: 400;
        line-height: 1.43;
        font-family: Inter;

        #root {
            min-height: rem(100);
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
        }

        p {
            margin: 0;
        }

        * {
            box-sizing: border-box;
        }

        .ellipsisPro {
            overflow: hidden;
            word-wrap: break-word;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 100%;
        }

        .clearfix {
            &:after {
                visibility: hidden;
                display: block;
                font-size: 0;
                content: ' ';
                clear: both;
                height: 0;
            }
        }

        .MuiButton-root {
            border-radius: 8px;
            // padding: 0 16px;
            background: $green400;
            border: 1px solid $green400;
            box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
            font-size: 14px;
            font-weight: 600;
            line-height: inherit;
            text-transform: none;
            font-family: inherit;
            cursor: pointer;
            outline: none;
            color: $green300;

            &.Mui-disabled {
                opacity: 0.5;
            }

            &:hover {
                background: $green400;
            }

            svg {
                width: 20px;
                height: 20px;
                margin-right: 8px;
            }
        }

        .surveyNotValid {
            padding: rem(2) rew(3);
            text-align: center;
            font-size: rem(2);
            color: $red100;

            &.status412 {
                color: $green100;
            }
        }
    }
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    border-radius: 4px;
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: #05caba;
    border-radius: 4px;
    &:hover {
        background: #04b2a4;
    }
}
