@import '../../sass/variable';

.industries_card_section {
    gap: 24px;
    .industries_cards {
        position: relative;
        display: flex;
        justify-content: center;
        justify-content: space-between;
        border: 1px solid #1d2939;
        padding: 80px 40px 60px 40px;
        .industries_card_contentL {
            width: calc(50% - 33px);
            .card_heading {
                font-size: 30px;
                font-weight: 500;
                line-height: 38px;
                color: $green500;
                display: flex;
                align-items: center;
                gap: 12px;
            }
            .card_description {
                font-size: 20px;
                line-height: 30px;
                font-weight: 400;
                color: $white400;
            }
        }
        .industries_card_contentR {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;
            justify-content: center;
            width: calc(50% - 33px);
            .industries_chip_card {
                padding: 12px;
                border-radius: 12px;
                font-size: 20px;
                font-weight: 600;
                line-height: 30px;
                letter-spacing: 0em;
                text-align: left;
                color: $green500;
                text-align: center;
                border: 1px solid $grey400;
            }
        }
    }
}

@media (max-width: 768px) {
    .industries_card_section {
        padding: 12px 10px;
        margin-top: 14px;
        .industries_cards {
            flex-direction: column;
            gap: 32px;
            border: none;
            padding: 0;
            .industries_card_contentL {
                width: 100%;
                .card_heading {
                    font-size: 20px;
                    line-height: 30px;
                }
                .card_description {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
            .industries_card_contentR {
                width: 100%;
                .industries_chip_card {
                    padding: 6px;
                    border-radius: 6px;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 18px;
                    border: 1px solid $grey400;
                }
            }
        }
    }
}
