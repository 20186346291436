@import '../../sass/variable.scss';

.contact_form_section {
    padding: 60px 0;
    background: #121212;
    .contact_form_container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 48px;
        .contact_form_contentL {
            width: calc(60% - 16px);
            .contact_form_title {
                font-size: 36px;
                line-height: 44px;
                letter-spacing: -0.02;
                font-weight: 600;
                color: $green500;
            }
            .contact_form_subTitle {
                font-size: 18px;
                line-height: 28px;
                font-weight: 400;
                color: $white300;
            }
            .input_halfs {
                display: flex;
                flex-wrap: wrap;
                gap: 32px;
                background: $black;
                padding: 40px;
                border: 1px solid #1d2939;
                border-radius: 6px;

                .warning {
                    margin-top: 8px;
                    color: $red100;
                    font-weight: 500;
                }

                .input_half,
                .input_full {
                    display: flex;
                    flex-direction: column;
                    label {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 20px;
                        color: $white300;
                    }

                    .MuiInputBase-root {
                        border-radius: 8px;
                        border: 1px solid $siteTextColor;
                        background: $black600;
                        box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
                        margin-top: 8px;
                        input {
                            height: inherit;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 18px;
                            color: $white300;
                            padding: 10px 14px;
                        }
                        textarea {
                            height: inherit;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 18px;
                            color: $white300;
                            padding: 10px 14px;
                        }

                        input[type='number']::-webkit-outer-spin-button,
                        input[type='number']::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }
                    }
                }
                .input_half {
                    width: calc(50% - 16px);
                }
                .input_full {
                    width: 100%;
                }
                Button {
                    padding: 12px 0;
                    color: $white300;
                    background: $green500;
                }
            }
        }
        .contact_form_contentR {
            width: calc(40% - 16px);
            padding-top: 32px;
            img {
                width: 100%;
                height: auto;
                min-height: 667px;
                border-radius: 8px;
            }
        }
    }
}

@media (max-width: 768px) {
    .contact_form_section {
        padding: 32px 0;
        .contact_form_container {
            flex-direction: column;
            align-items: center;

            .contact_form_contentL {
                width: 100%;
                flex-direction: column;
                .input_halfs {
                    width: 100%;
                    padding: 32px 12px;
                    .input_half {
                        width: 100%;
                    }
                }
            }

            .contact_form_contentR {
                display: none;
            }
        }
    }
}
