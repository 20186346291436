@import '../../sass/variable';

.cards3y {
    padding: 60px 0;
    background-color: $black700;

    .card_group3y_container {
        display: flex;
        flex-direction: column;
    }

    .card3yGroupTitle {
        color: $white300;
        font-size: 36px;
        line-height: 44px;
        font-weight: 500;
        text-align: center;
        padding: 60px 0;
    }

    .card3y_groups {
        display: flex;
        gap: 30px;

        .card_group_item3y {
            width: 35%;
            padding: 32px 24px;
            border: 1px solid $blue200;
            border-radius: 16px;
            background: linear-gradient(
                180deg,
                rgba(43, 255, 255, 0.12) 0%,
                rgba(43, 255, 255, 0.04) 50%,
                rgba(43, 255, 255, 0.07) 100%
            );

            .card3_container {
                .card3_item {
                    .card3y_content {
                        .card_title3y {
                            font-size: 32px;
                            line-height: 38px;
                            font-weight: 600;
                            color: $white300;
                            margin: 0;
                        }

                        .card_subtitle3y {
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 24px;
                            color: $white500;
                        }

                        h2 span {
                            position: relative;
                            top: -6px;
                        }

                        h3 .card_title3y {
                            font-size: 24px;
                            font-weight: 500;
                            line-height: 30px;
                            padding-right: 8px;
                        }

                        .card_button3y {
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 20px;
                            color: $green1200;
                            padding: 16px 14px;
                            margin: 32px 0;
                            border: 1px solid $green1200;
                            border-radius: 8px;
                            width: 100%;
                            background: $green1300;
                        }

                        .card_list_item3y {
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 22px;
                            color: $white500;
                            margin-bottom: 16px;
                        }
                    }
                }
            }
        }
    }

    .card3y_mobile {
        display: none;
        gap: 32px;
        .cardtabs_section {
            display: flex;
            justify-content: space-between;
            padding: 12px 10px;
            background-color: $black700;
            border: 1px solid $green1200;
            border-radius: 8px;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);

            .cardtabs_container {
                font-size: 16px;
                font-weight: 700;
                line-height: 30px;
                cursor: pointer;
                padding: 6px 8px;
                border-radius: 8px;
                color: $white300;

                &.cardactive_tab {
                    background: $green1300;
                    color: $green1200;
                }
            }
        }
    }

    .card_group_item3y_mobile {
        width: 100%;
        padding: 32px 24px;
        border: 1px solid $blue200;
        border-radius: 16px;
        background: linear-gradient(
            180deg,
            rgba(43, 255, 255, 0.12) 0%,
            rgba(43, 255, 255, 0.04) 50%,
            rgba(43, 255, 255, 0.07) 100%
        );
        margin: 0;

        .card3_container {
            .card3_item {
                align-items: left;
                gap: 16px;

                .card3y_content {
                    .card_title3y {
                        font-size: 32px;
                        line-height: 30px;
                        font-weight: 600;
                        color: $white300;
                    }

                    .card_subtitle3y {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        color: $white500;
                        margin-top: -15px;
                    }

                    h2 span {
                        position: relative;
                        top: -6px;
                    }

                    h3 .card_title3y {
                        font-size: 24px;
                        font-weight: 500;
                        line-height: 30px;
                        padding-right: 8px;
                    }

                    .card_button3y {
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 20px;
                        color: $green1200;
                        padding: 16px 14px;
                        margin-bottom: 42px;
                        margin-top: 40px;
                        border: 1px solid $green1200;
                        border-radius: 8px;
                        width: 100%;
                        background: $green1300;
                    }

                    .card_list_item3y {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 22px;
                        color: $white500;
                        margin-bottom: 1rem;
                    }
                }
            }
        }
    }
}

.cards4y {
    padding: 60px 0;
    background-color: $black800;

    .card_group4y_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        .plan_comparison_mobile {
            display: none;

            .plan_comparison_wrapper {
                width: 90%;

                .plan_comparison_content {
                    display: flex;
                    flex-direction: column;
                    background: #131c1c;
                    gap: 30px;
                    margin: 32px 0;
                    border: 1px solid #163a3a;
                    border-radius: 16px;
                    padding: 24px;
                    color: $white300;
                    .plan_comparison_title {
                        font-size: 20px;
                        font-weight: 600;
                        line-height: 32px;
                    }
                    .plan_comparison_plan {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 32px;
                        color: #9ea0a1;
                    }
                    .plan_comparison_value {
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 32px;
                    }
                }
            }
        }
    }

    .card4yGroupTitle {
        color: $white300;
        font-size: 38px;
        line-height: 44px;
        font-weight: 500;
        padding: 50px 0px;
    }

    .card4y_groups {
        display: flex;
        gap: 20px;
        width: 100%;

        .card_group_item4y {
            width: 25%;
            height: 100%;
            margin-bottom: 120px;
            border: 1px solid $blue200;
            border-radius: 16px;
            background: $black900;

            .card4_container {
                .card4_item {
                    .card4y_content {
                        color: $white300;
                        margin-left: 20px;

                        .card_title4y {
                            font-size: 22px;
                            line-height: 32px;
                            font-weight: 400;
                            margin-left: 15px;
                            margin-bottom: 40px;
                        }

                        .card_list4y {
                            font-size: 20px;
                            font-weight: 400;
                            line-height: 32px;
                            padding: 0 8px;
                            margin-bottom: 10px;
                        }

                        .card_list_item4y {
                            font-size: 18px;
                            font-weight: 400;
                            line-height: 32px;
                            align-items: center;
                            padding: 0 8px;
                            margin-bottom: 16px;
                        }
                    }
                }
            }
        }

        .card_group_item4y:first-child {
            width: 350px;
            height: 100%;
            background-color: #101621;
            border: none;
            padding-top: 50px;
        }

        .card_group_item4y:first-child .card4y_content .card_list4y,
        .card_group_item4y:first-child .card4y_content .card_list_item4y {
            color: #9ca1a9;
        }
    }
}

@media (max-width: 768px) {
    .cards3y {
        .card3yGroupTitle {
            font-size: 28px;
            padding: 32px 0;
        }
        .card3y_groups {
            display: none;
        }
        .card3y_mobile {
            display: flex;
            flex-direction: column;
        }
    }

    .cards4y {
        padding: 0;
        background: $black700;
        .card4yGroupTitle {
            font-size: 28px;
            padding: 12px 0;
        }
        .card4y_groups {
            display: none;
        }
        .card_group4y_container {
            .plan_comparison_mobile {
                display: flex;
                width:100%;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
