.card4xV {
    padding: 60px 0;
    background: #101621;
    padding-bottom: 60px;


    .card_group4xV_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;

    }

    .cardGroupTitle {
        color: white;
        font-size: 36px;
        line-height: 44px;
        letter-spacing: -0.02%;
        font-weight: 500;
        text-align: center;
        margin-bottom: 80px;
        margin-top: 10px;
        width: 750px;
    }

    .card_groups {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        gap: 32px;


        .card_group_item4xV {
            max-width: 253px;
            min-width: 200px;
            margin-bottom: 15px;
            border: 1px solid #16343E;
            background-color: #141a25;
            border-radius: 16px;
            position: relative;


            .card_button {
                position: absolute;
                top: -30px;
                left: 50%;
                transform: translateX(-50%);
            }

            .circle_button {
                width: 63px;
                height: 63px;
                border-radius: 50%;
                background-color: #101621;
                color: white;
                font-size: 16px;
                border: 1px solid #16343E;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
            }

            .card_container {
                .card_item {
                    .card_image4x {
                        width: 250px;
                        height: 165px;

                        img {
                            max-width: 100%;
                            height: auto;
                            border-radius: 16px;
                        }
                    }

                    .card_content {
                        text-align: center;
                        color: #fcfcfd;

                        .card_title4xV {
                            font-size: 18px;
                            line-height: 30px;
                            font-weight: 600;
                            margin-bottom: 0.5rem;
                        }

                        .card_text4xV {
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 18px;
                            align-items: center;
                            color: #9fa2a7;
                            padding: 0 8px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .card4xV {
        .card_group4xV_container {
            display: flex;
            flex-direction: column;
        }

        .cardGroupTitle {
            width: 100%;
            text-align: center;
            padding: 0 12px;
            font-size: 30px;
            line-height: 38px;
            margin-bottom: 40px;
        }

        .card_groups {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            flex-direction: column;

            .card_group_item4xV {
                .card_container {
                    .card_item {
                        .card_image4x {
                            width: 100%;
                            height: 165px;

                            img {
                                width: 100%;
                                height: auto;
                                border-radius: 16px;
                            }
                        }

                        .card_content {
                            margin-bottom: 20px;

                            .card_title4xV {
                                font-size: 20px;
                            }

                            .card_text4xV {
                                padding: 8px 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}