@import '../../sass/variable';

.hero_section_wrapper {
    background: #121212;
    background-image: url('../../assets/industries/heroBanner.png');
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    padding: 60px 0 0 0;

    .hero_section {
        display: flex;
        gap: 90px;

        .hero_section_contentL {
            width: calc(62% - 33px);
            gap: 30px;

            .hero_section_title {
                font-size: 54px;
                line-height: 72px;
                letter-spacing: -0.02;
                font-weight: 500;
                margin-bottom: 0;
                color: $white300;
            }

            .hero_section_subTitle {
                font-size: 16px;
                line-height: 30px;
                font-weight: 400;
                color: $white;
            }

            .MuiButton-root {
                font-size: 16px;
                width: fit-content;
                background: $green500;
                color: $white;
                border: 0;
                font-weight: 600;
                padding: 16px 28px;
            }

            .MuiButton-root:hover {
                background: $green300;
            }
        }

        .hero_section_contentR {
            width: calc(40% - 33px);

            .card_item {
                display: flex;
                gap: 30px;
                padding-bottom: 30px;

                .card_title {
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 30px;
                    color: $white300;
                    margin: 0;
                }

                .card_text {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 400;
                    color: #f9fafb;
                    margin: 0;
                }
            }
        }
    }
}

.card_group4x {
    padding: 32px 0;
    background: #f8ffff;
    padding-bottom: 90px;
    margin: 0 0 90px 0;

    .cardGroupTitle {
        font-size: 36px;
        line-height: 44px;
        letter-spacing: -0.02;
        text-align: center;
        font-weight: 500;
        color: #344054;
        margin-bottom: 60px;
    }

    .card_group {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .card_group4X {
        justify-content: center;
        gap: 24px;

        .card_group_item4X {
            width: calc(25% - 22px);
            justify-content: unset !important;
            gap: 24px;

            .card_container {
                width: 100%;
                height: 100%;
                background: $white;
                border-radius: 8px;
                gap: 24px;
                border: 1px solid #00000026;

                .card_item {
                    padding: 24px 32px;
                    height: 100%;

                    .card_title4x {
                        font-size: 20px;
                        font-weight: 600;
                        line-height: 30px;
                        color: #04b2a4;
                    }

                    .card_text4x {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        color: #344054;
                    }
                }
            }
        }
    }
}

.card_group_container {
    .cards3x {
        display: flex;
        flex-direction: row;

        .cards-contens {
            display: flex;
            width: 33%;
            flex-direction: column;
            padding: 32px;

            &:nth-of-type(1) {
                background-color: transparent;

                .cardSubItem {
                    border: 1px solid black;
                    border-radius: 16px;
                    padding: 0 0 16px 16px;
                    width: 100%;
                    height: 100%;
                }
            }

            .card3x-title {
                font-size: 24px;
                font-weight: 500;
                line-height: 32px;
                color: $white300;
                margin-bottom: 20px;
                background-color: transparent;
                padding-left: 28px;
            }

            &.gradient-card {
                .cardSubItem {
                    background: linear-gradient(180deg,
                            rgba(43, 255, 255, 0.12) 0%,
                            rgba(43, 255, 255, 0.04) 50%,
                            rgba(43, 255, 255, 0.07) 100%);
                    border: 1px solid rgba(43, 255, 255, 0.32);
                    padding: 0 0 32px 16px;
                    border-radius: 16px;
                    width: 100%;
                }
            }

            .cardSubItem {
                .card3x-subItem {
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 32px;
                    color: $white300;
                    padding: 32px 0 0 16px;
                }
            }
        }
    }
}

.cards3x .light-text-card .cardSubItem .card3x-subItem {
    color: #9ca1a9 !important;
    font-weight: 400 !important;
}

.cards3x .colored-text-card .cardSubItem .card3x-subItem {
    color: #00d6c4 !important;
    font-weight: 500 !important;
}

.cards3x .cards-contens:nth-of-type(2) {
    .cardSubItem {
        background: rgba(43, 255, 255, 0.02) 20% !important;
    }
}

@media (max-width: 768px) {
    .hero_section_wrapper {
        .hero_section {
            flex-direction: column;
            gap: 0;

            .hero_section_contentL {
                width: 100%;
                gap: 30px;

                .hero_section_title {
                    font-size: 30px;
                    line-height: 38px;
                }

                .hero_section_subTitle {
                    font-size: 14px;
                    line-height: 20px;
                }

                .MuiButton-root {
                    padding: 10px 20px;
                    padding-top: 12px;
                }
            }

            .hero_section_contentR {
                width: 100%;
                text-align: left;

                .card_item {
                    .card_title {
                        font-size: 18px;
                        line-height: 28px;
                    }

                    .card_text {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }
        }
    }

    .card_group4x {
        .cardGroupTitle {
            font-size: 30px;
            line-height: 38px;
        }

        .card_group {
            flex-direction: column;
        }

        .card_group4X {
            justify-content: center;

            .card_group_item4X {
                width: 100%;
            }
        }
    }

    .card_group_container {
        .cards3x {
            flex-direction: column;
            gap: 10px;
            padding: 10px 0;

            .cards-contens {
                width: 100%;
                padding: 32px 15px;
                padding-bottom: 5px;
            }
        }
    }
}