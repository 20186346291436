@import '../../sass//variable.scss';

.heroBanner {
    background: #121212;
    background-image: url('../../assets/industries/heroBanner.png');
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    padding: 60px 0;

    .heroContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;

        .heroContainerTitle {
            font-size: 60px;
            line-height: 72px;
            letter-spacing: -0.02;
            text-align: center;
            margin-bottom: 16px;
            color: $white300;
            font-weight: 600;
        }

        .heroContainerBody {
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: 0em;
            text-align: center;
            color: $white300;
            margin-bottom: 32px;
        }

        .heroChips {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 10px;

            .heroChip {
                font-size: 18px;
                line-height: 28px;
                font-weight: 500;
                color: $green300;
                padding: 6px 12px;
                border: 1px solid;
                border-image-source: linear-gradient(0deg, rgba(2, 107, 98, 0.3), rgba(2, 107, 98, 0.3)),
                    linear-gradient(291.01deg, rgba(155, 224, 219, 0) 57.08%, rgba(155, 224, 219, 0.24) 82.89%),
                    linear-gradient(99.14deg, rgba(155, 224, 219, 0) 75.64%, rgba(155, 224, 219, 0.24) 96.21%);
                border-radius: 30px;
                width: fit-content;
            }
        }

        .MuiButton-root {
            font-size: 18px;
            line-height: 28px;
            width: fit-content;
            margin: 48px;
            background-color: #04b2a4;
            color: $white;
            border: 0;
            font-weight: 600;
            padding: 16px 28px;
        }
    }
}

.feature_section {
    background: #121212;
    overflow: hidden;
    padding-bottom: 60px;

    .features_container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .feature-title {
            width: 80%;
            font-size: 48px;
            line-height: 60px;
            font-weight: 600;
            text-align: center;
            color: $white300;
        }

        .features-content {
            display: flex;
            justify-content: space-between;

            .feature-contentL {
                width: calc(45% - 33px);

                .feature-text {
                    font-size: 24px;
                    line-height: 32px;
                    font-weight: 500;
                    color: $white300;
                    padding: 16px;
                    border-radius: 8px;
                    margin-bottom: 24px;
                    background: #10182880;
                    cursor: pointer;
                }

                .feature-text.selected {
                    color: $green500;
                    background: #131c1c;
                    border-left: 4px solid $green300;
                }
            }

            .feature-contentR {
                width: calc(55% - 33px);
                height: 100%;
                min-height: 424px;
            }
        }

        .mobile-feature-content {
            display: none;
        }
    }
}

@keyframes scrollX {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

.text-carousel-section {
    padding: 60px 0;
    background: #111621;

    .text-carousel-container {
        text-align: center;
        margin-bottom: 32px;

        .carouselTitle {
            font-size: 44px;
            line-height: 60px;
            letter-spacing: -0.02;
            font-weight: 600;
            color: $white400;
            margin: 0;
        }
    }

    .text-carousel {
        display: flex;
        width: 100%;
        overflow: hidden;
        user-select: none;
        mask-image: linear-gradient(to right,
                hsl(0 0% 0% / 0),
                hsl(0 0% 0% / 1) 10%,
                hsl(0 0% 0% / 1) 90%,
                hsl(0 0% 0% / 0));

        .carousel-group,
        .carousel-group2 {
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: space-around;
            white-space: nowrap;
            animation: scrollX 30s linear infinite;

            .text-group {
                display: grid;
                place-items: center;
                // width: clamp(1rem, 1rem + 40vmin, 30rem);
                padding: calc(clamp(10rem, 1rem + 30vmin, 30rem) / 10);

                .text {
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                    font-size: 24px;
                    line-height: 32px;
                    font-weight: 500;
                    border-radius: 8px;
                    background: linear-gradient(90deg, rgba(4, 178, 164, 0.46) 0%, rgba(4, 178, 164, 0) 100%);
                    padding: 8px 12px;
                    color: $white400;
                }

                .text-center {
                    background: linear-gradient(270deg, rgba(4, 178, 164, 0.46) 0%, rgba(4, 178, 164, 0) 100%);
                }
            }
        }

        .carousel-group2 {
            animation-direction: reverse;
            animation-delay: -3s;
        }
    }
}

.card-group-feature {
    padding: 60px 0;
    background: #121212;

    .card-group-title {
        font-size: 48px;
        font-weight: 600;
        line-height: 60px;
        letter-spacing: -0.02em;
        text-align: center;
        color: $white400;
    }

    .card-group {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .card-group3xV {
        justify-content: center;
        gap: 24px;

        .card-group-item3xV {
            width: calc(33% - 22px);
            justify-content: unset !important;

            .card_container {
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg,
                        rgba(43, 255, 255, 0.12) 0%,
                        rgba(43, 255, 255, 0.04) 50%,
                        rgba(43, 255, 255, 0.07) 100%);
                border-radius: 16px;
                border: 1px solid;
                border-image-source: linear-gradient(180deg,
                        rgba(43, 255, 255, 0.32) 0%,
                        rgba(43, 255, 255, 0.1) 50%,
                        rgba(43, 255, 255, 0.16) 100%);

                .card_item {
                    padding: 24px 32px;
                    height: 100%;

                    .card_title {
                        font-size: 20px;
                        font-weight: 500;
                        line-height: 30px;
                        color: $green500;
                    }

                    .card_body {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        color: $white400;
                    }
                }
            }
        }

        .card-group-item-half {
            width: calc(50% - 22px);
        }
    }
}

@media (max-width: 768px) {
    .heroBanner {
        padding: 0;

        .heroContainer {
            padding: 60px 10px;
            padding-bottom: 0;

            .heroContainerTitle {
                font-size: 30px;
                line-height: 38px;
            }

            .heroContainerBody {
                font-size: 14px;
                line-height: 20px;
            }

            .MuiButton-root {
                padding: 10px 18px;
            }
        }
    }

    .feature_section {
        overflow: auto;

        // padding: 12px 6px;
        .features_container {
            .feature-title {
                width: 100%;
                font-size: 30px;
                line-height: 38px;
            }

            .features-content {
                display: flex;
                flex-direction: column;

                .feature-contentL {
                    display: none;
                }

                .feature-contentR {
                    display: none;
                }
            }

            .mobile-feature-content {
                display: flex;
                flex-direction: column;
                gap: 32px;
            }
        }
    }

    .text-carousel-section {
        padding: 32px 0;

        .text-carousel-container {
            .carouselTitle {
                font-size: 30px;
                line-height: 38px;
            }
        }
    }

    .card-group-feature {
        padding: 60px 6px;
        padding-bottom: 30px;

        .card-group-title {
            font-size: 28px;
            line-height: 38px;
            text-align: center;
            margin-top: 0px;
        }

        .card-group {
            flex-direction: column;
            margin-bottom: 30px;
        }

        .card-group3xV {
            justify-content: center;
            gap: 24px;

            .card-group-item3xV {
                width: 100%;

                .card-container {
                    border-radius: 12px;
                }
            }
        }
    }
}