@import '../../sass/variable';

.card2xv_group_wrapper {
    background: #121212;
    padding: 60px 0;
    padding-bottom: 90px;

    .card2xv_group_heading {
        font-size: 36px;
        line-height: 44px;
        font-weight: 500;
        letter-spacing: -0.02;
        text-align: center;
        color: $white300;
    }

    .card2xv_group {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 24px;
    }

    .card_group2xv {
        .card_group_item2xv {
            justify-content: unset !important;
            gap: 24px;

            .card2xv_container {
                width: 100%;
                height: 100%;
                background: $black;
                border-radius: 8px;
                gap: 24px;
                border: 1px solid $black500;

                .card2xv_item {
                    padding: 24px 32px;
                    height: 100%;

                    .card2xv_itemV {
                        display: flex;
                        flex-direction: row;
                        gap: 20px;
                    }

                    .card2xv_title {
                        font-size: 20px;
                        font-weight: 500;
                        line-height: 30px;
                        color: $green500;
                    }

                    .card2xv_text {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        color: $white400;
                    }
                }
            }
        }
    }

    .card_group_item2xv:nth-child(1),
    .card_group_item2xv:nth-child(2),
    .card_group_item2xv:nth-child(3) {
        grid-row: span 1;
    }

    .card_group_item2xv:nth-child(4),
    .card_group_item2xv:nth-child(5) {
        grid-row: span 1;
    }
}

@media (max-width: 768px) {

    .card2xv_group_wrapper {
        padding: 32px 0;
        padding-bottom: 60px;

        .card2xv_group_heading {
            font-size: 30px;
            font-weight: 600;
            line-height: 38px;
            text-align: center;
        }

        .card2xv_group {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }

        .card_group2xv {
            justify-content: center;
            gap: 16px;

            .card_group_item2xv {
                width: 100%;

                .card2xv_container {
                    border-radius: 12px;
                    padding: 16px;
                }
            }
        }
    }
}