@import '../../sass/variable';

.hero_sec_wrapper {
    background: #121212;
    background-image: url('../../assets/industries/heroBanner.png');
    background-size: cover;
    padding: 100px 0 0 0;

    .hero_sec {
        display: flex;
        flex-direction: column;
        gap: 100px;

        .hero_sec_row1 {
            display: flex;
            gap: 20px;
            width: 100%;

            .hero_sec_columnL {
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: 3px;

                .hero_sec_title {
                    font-size: 54px;
                    line-height: 72px;
                    letter-spacing: -0.02;
                    font-weight: 500;
                    margin-bottom: 0;
                    color: #fcfcfd;
                }

                .hero_sec_body {
                    font-size: 16px;
                    line-height: 30px;
                    font-weight: 400;
                    color: $white300;
                    width: 65%;
                }

                .hero_sec_subtitle {
                    font-size: 12px;
                    line-height: 30px;
                    font-weight: 400;
                    color: #fcfcfd;
                    margin-top: -10px;
                }
            }

            .hero_sec_columnR {
                display: flex;
                align-items: center;
            }
        }

        .hero_sec_row2 {
            .hero_paragraph {
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
                color: #F9FAFB;
                margin-bottom: 20px;
                text-align: left;
            }
        }
    }
}

.tabular_sec_wrapper {
    background: #101621;
    background-size: cover;
    overflow: hidden;
    padding: 60px 0;
    margin-top: 70px;

    .tabular_sec {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 60px;

        .tabular_sec_title {
            font-size: 36px;
            line-height: 44px;
            letter-spacing: -0.02em;
            font-weight: 500;
            color: #FFFFFF;
            text-align: left;
            margin-bottom: 20px;
        }

        p {
            margin: 20px 0;
            line-height: 26px;
            font-size: 16px;
            font-weight: 400;
            color: #e0e0e0;
            text-align: left;
        }

        .tabular_sec_table {
            width: 100%;
            background-color: #10141d;
            border-radius: 16px;
            border: 1px solid #16343E;
            overflow: hidden;
            table-layout: fixed;

            th {
                font-size: 18px;
                line-height: 32px;
                font-weight: 400;
                color: #F9FAFB;
                padding: 24px 24px;
                text-align: left;
                border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                background-color: #161c26 !important;
                margin-bottom: 16px;
            }

            tbody {
                tr {
                    &:nth-child(odd) {
                        background-color: #10141d;
                    }

                    margin-bottom: 20px;

                    &:nth-child(even) {
                        background-color: #10141d;
                    }
                }

                td {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 400;
                    color: #FFFFFF;
                    padding: 24px 24px;
                    border-bottom: 1px solid #10141d;
                    text-align: left;
                    width: 30%;
                    vertical-align: top;
                }

                .main {
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: 500;
                }
            }
        }

    }
}

.data_wrapper {
    background-size: cover;
    color: white;
    padding: 20px 0 0 0;

    .data_sec {
        .section-block {
            margin-bottom: 10px;
        }

        h3 {
            margin: 30px 0;
            font-size: 36px;
            line-height: 44px;
            font-weight: 600;
            color: #f5f5f5;
            text-align: left;
        }

        h4 {
            margin: 10px 0;
            font-size: 20px;
            line-height: 30px;
            font-weight: 500;
            color: #f5f5f5;
            text-align: left;
        }

        p {
            margin: 10px 0;
            line-height: 26px;
            font-size: 16px;
            font-weight: 400;
            color: #e0e0e0;
            text-align: left;
        }

        span {
            line-height: 26px;
            font-size: 16px;
            font-weight: 300;
            color: #e0e0e0;
            text-align: left;
        }
    }
}

.list_wrapper {
    background-size: cover;
    color: white;
    padding: 50px 0 0;

    .list_sec {
        .section-block {
            margin-bottom: 10px;
        }

        h3 {
            margin: 30px 0;
            font-size: 36px;
            line-height: 44px;
            font-weight: 600;
            color: #f5f5f5;
            text-align: left;
        }

        h4 {
            margin: 20px 0;
            font-size: 20px;
            line-height: 30px;
            font-weight: 400;
            color: #f5f5f5;
            text-align: left;
        }

        p {
            margin: 10px 0;
            line-height: 26px;
            font-size: 16px;
            font-weight: 400;
            color: #e0e0e0;
            text-align: left;
        }

        ul {
            margin: 10px 0;
            padding-left: 20px;
            color: #f9fafb;
            list-style-type: disc;
        }

        li {
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            text-align: left;
        }
    }
}

@media (max-width: 768px) {
    .hero_sec_wrapper {
        padding: 100px 0 0px 10px;

        .hero_sec {
            gap: 40px;

            .hero_sec_row1 {
                flex-direction: column;

                .hero_sec_columnL {
                    .hero_sec_title {
                        font-size: 36px;
                        line-height: 38px;
                        margin-bottom: 10px;
                        margin-top: 20px;
                        text-align: center;
                        font-weight: 500;
                    }

                    .hero_sec_body {
                        font-size: 16px;
                        line-height: 24px;
                        text-align: center;
                        width: 100%;
                    }

                    .hero_sec_subtitle {
                        font-size: 12px;
                        line-height: 30px;
                        font-weight: 400;
                        color: gray;
                        margin-top: -10px;
                        text-align: center;
                    }
                }

                .hero_sec_columnR {
                    display: none;
                }
            }

            .hero_sect_row2 {
                .hero_paragraph {
                    font-size: 16px;
                    line-height: 24px;
                    margin-bottom: 10px;
                    text-align: left;
                }
            }
        }
    }

    .data_wrapper {
        padding-left: 10px;

        .data_sec {
            .section-block {
                margin-bottom: 40px;
                padding: 0px;
            }

            h3 {
                font-size: 30px;
                line-height: 38px;
            }

            h4 {
                font-size: 24px;
                line-height: 32px;
                margin-bottom: 10px;
            }

            p {
                line-height: 24px;
                font-size: 16px;
                letter-spacing: -0.02;
            }

            span {
                margin-bottom: 4px;
                font-size: 16px;
                line-height: 24px;
            }
        }
    }

    .list_wrapper {
        padding: 30px 0px 0 10px;

        .list_sec {
            .section-block {
                margin-bottom: 40px;
                padding: 0px;

                h3 {
                    font-size: 30px;
                    line-height: 38px;
                }
            }

            h4 {
                font-size: 24px;
                line-height: 32px;
                margin-bottom: 30px;
            }

            p {
                margin: 8px 0;
                line-height: 24px;
                font-size: 16px;
                letter-spacing: -0.02;
            }

            ul {
                margin: 8px 0;
            }

            li {
                margin-bottom: 4px;
            }
        }
    }
}

@media (max-width: 768px) {
    .tabular_sec_wrapper {
        background: #101621;
        background-size: cover;
        padding: 0px;
        margin-top: 70px;

        .tabular_sec {
            .tabular_sec_title {
                font-size: 30px;
                line-height: 38px;
            }
        }
    }

    .mobile-row {
        margin-bottom: 20px;
    }

    .mobile-title {
        font-weight: 500;
        margin-bottom: 10px;
        font-size: 28px;
        line-height: 38px;
        color: #f5f5f5;
        text-align: left;
    }

    .mobile-row p {
        margin-left: 20px;
        line-height: 24px;
        font-size: 16px;
        letter-spacing: -0.02;
        text-align: center;
    }
}

@media (max-width: 768px) {
    .desktop-table {
        display: none;
    }

    .mobile-view {
        display: block;
    }
}

@media (min-width: 769px) {
    .desktop-table {
        display: table;
    }

    .mobile-view {
        display: none;
    }
}