@import '../../../src/sass/functions';
@import '../../../src/sass/variable';

.errorPage {
    background: $white;
    height: rem(100);
    width: 100%;
    text-align: center;
    padding: rem(8) rem(3) 0;
    position: relative;

    img {
        width: rew(23);

        &.logo {
            width: inherit;
            max-width: rem(14);
            position: absolute;
            bottom: rem(8);
            left: 50%;
            transform: translateX(-50%);
        }
    }

    h1 {
        font-family: 'Bower';
        font-weight: bold;
        font-size: rem(6);
        line-height: rem(7.2);
        color: $black;
        margin: rem(8) 0 rem(4);
    }

    p {
        margin: 0;
        font-size: rem(2);
        line-height: rem(3.2);
        color: $black;
        font-weight: 300;
    }
}
