@import '../../sass/variable.scss';

.feature-card {
    background: linear-gradient(164.95deg, rgba(43, 255, 255, 0.1) 0%, rgba(43, 255, 255, 0.02) 50%, rgba(43, 255, 255, 0.06) 100%);
    padding: 24px;
    text-align: left;
    border-radius: 16px;

    .feature-icon {
        display: block;
        margin: 0 auto 40px auto;
    }

    .feature-subTitle {
        font-size: 20px;
        line-height: 30px;
        font-weight: 600;
        color: $white300;
        margin-bottom: 18px;
    }

    .feature-body {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: $white400;
        margin-bottom: 12px;
    }
}

@media (max-width: 768px) {
    .feature-card {
        .feature-icon {
            width: 80px;
            height: 80px;
        }
        .feature-body {
            font-size: 14px;
            line-height: 20px;
        }
    }
}