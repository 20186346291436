@import '../../sass/variable';
@import '../../sass/functions';

.addModalRoot {
    .modalContent {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 24px;
        width: 512px;
        border-radius: 12px;
        background: $white;
        box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0 20px 24px -4px rgba(16, 24, 40, 0.08);
        color: $black200;
        outline: none;
        max-height: rem(90);
        overflow-y: auto;

        h2 {
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
            margin: 0 0 32px;
            position: relative;

            .closeButton {
                position: absolute;
                padding: 0;
                right: 0;
                width: 24px;
                height: 24px;
                color: $black200;
            }
        }
    }
}

@media (max-width: 768px) {
.addModalRoot {
    .modalContent {
        width: 312px;
    }
}
}
