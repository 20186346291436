@import '../../sass/variable';

header {
    background-color: #101828;

    .header_content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 58px;
    }

    .header_menu {
        display: flex;
        align-items: center;
        gap: 24px;
        cursor: pointer;

        .icon {
            padding-top: 6px;
        }

        .header_menu_items {
            color: #fff;
            text-decoration: none;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            display: flex;
            align-items: center;
            cursor: pointer;

            &.button {
                border-radius: 8px;
                height: 36px;
                color: #038e83;
                font-weight: 600;
                background: $white300;
                padding: 8px 14px;
            }
            &.button:hover {
                font-weight: 700;
            }
        }

        .header_menu_items:hover {
            color: #038e83;           
        }

        .login_link {
            border: 1px solid #00d6c4;
            color: #00d6c4;
            padding: 8px 14px;
            border-radius: 8px;
        }
    }

    .mobileBtn {
        display: none;
        color: #fff;
    }

    div.mobileDrawer {
        display: none;
    }
}

section.solutions_dropdown {
    position: absolute;
    top: 58px;
    left: 20%;
    width: 60%;
    background-color: #101828;
    z-index: 99;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1);

    .dropdown_content {
        display: flex;
        justify-content: space-around;
        padding: 0 20px 20px 20px;
        gap: 32px;
        width: 100%;

        .dropdown_column {
            display: flex;
            flex-direction: column;
            gap: 8px;

            h4 {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                color: #9da0a5;
                margin-bottom: 8px;
                padding-left: 8px;
            }

            .menuNavItem {
                color: #fcfcfd;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                cursor: pointer;
                padding: 8px;
                border-radius: 4px;
                display: flex;
                border: 1px solid transparent;

                &:hover {
                    background: #0247425e;
                    border: 1px solid #024742;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .header_content {
        .header_menu {
            display: none;
        }

        .mobileBtn {
            display: block;
            color: #fff;
        }
    }

    .mobileDrawer {
        background-color: #101828;
        color: #fff;
        height: auto;
        width: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;

        .mobileDrawerContent {
            padding: 25px 0;

            div.header-content {
                display: flex;
                padding: 0 18px;
                justify-content: space-between;
                margin-bottom: 15px;

                .icone {
                    display: flex;
                    align-items: center;
                }
            }

            div.ul {
                list-style: none;
            }

            .list {
                color: #fff;
                font-size: 16px;
                padding: 16px 18px;
                cursor: pointer;
                border-bottom: 1px solid #475467;
                display: block;

                a {
                    text-decoration: none;
                    color: white;
                }
            }
        }

        button {
            background-color: #00d6c4;
            color: #fff;
            padding: 12px 20px;
            border-radius: 8px;
            font-weight: 600;
            width: calc(100% - 40px);
            border: none;
            text-align: center;
            transition: background-color 0.3s ease;
            margin: 20px;
            margin-top: 30px;

            &:hover {
                background-color: #038e83;
            }
        }
    }

    section.solutions_dropdown {
        display: none;

        .dropdown_content {
            .dropdown_column {
                display: none;
            }
        }
    }

    .mobileDropdown {
        display: flex;
        flex-direction: column;
        background-color: #101828;
        padding: 10px;
        border-radius: 4px;
        gap: 5px;

        h4 {
            margin: 0;
            font-size: 14px;
            color: #9da0a5;
        }

        .menuNavItem {
            padding: 8px;

            &:hover {
                background: #0247425e;
                border: 1px solid #024742;
            }
        }
    }
}

@media (min-width: 769px) {
    .mobileDrawer {
        display: none;
    }
}