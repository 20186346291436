@import '../../sass/variable';

.hero_section_content {
    text-align: center;
    padding: 90px 0 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    position: relative;
    z-index: 2;

    video {
        border-radius: 12px;
        width: 100%;
    }

    h3 {
        font-size: 60px;
        font-weight: 600;
        line-height: 72px;
        letter-spacing: -0.02em;
        text-align: center;
        color: $white;
        margin: 0;
        margin-bottom: 16px;
    }

    h5 {
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        text-align: center;
        color: $white;
        margin: 0;
        margin-bottom: 24px;
    }

    .MuiButton-root {
        font-size: 16px;
        width: fit-content;
        margin-bottom: 48px;
        background: $green500;
        color: $white;
        border: 0;
        font-weight: 600;
        padding: 16px 28px;
    }

    .MuiButton-root:hover {
        background: $green300;
    }
}

.hero_section_content_bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .hero_section_content_bg_1 {
        height: 55%;
        position: relative;
        z-index: 2;
        color: white;
        opacity: 0.3;

        &::before {
            content: '';
            // background-image: url('../../assets/hero_banner.png');
            background-size: cover;
            background-position: center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0.6;
        }
    }

    .hero_section_content_bg_2 {
        height: 45%;
        background: #121212;
    }
}

.features_section {
    background: #121212;
    // background: #0B192A;
    padding: 60px 0;

    .feature_container {
        display: flex;
        justify-content: space-between;

        .desktop_feature_content {
            width: calc(50% - 33px);
            display: flex;
            flex-direction: column;
            justify-content: center;

            img {
                width: 100%;
                height: 80%;
            }

            .MuiPaper-root.MuiPaper-elevation {
                background: transparent !important;

                .MuiAccordionSummary-content {
                    display: flex;
                    gap: 8px;
                    align-items: center;
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 32px;
                    color: #04b2a4;

                    .accordian_header_number {
                        font-size: 14px;
                        font-weight: 600;
                        border: 1px solid #04b2a4;
                        border-radius: 50%;
                        height: 24px;
                        width: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                .MuiAccordionDetails-root {
                    color: $white300;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 30px;
                    width: 85%;
                }
            }
        }

        @keyframes border-animation {
            0% {
                width: 0;
            }

            100% {
                width: 100%;
            }
        }

        .MuiPaper-root.MuiPaper-elevation.expanded {
            background: $green900 !important;
            border-radius: 8px;
            position: relative;
            overflow: hidden;
        }

        .MuiPaper-root.MuiPaper-elevation.expanded::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 2px;
            background: $green500;
            border-radius: 8px;
            animation: border-animation 10s linear;
        }

        .mobile_feature_content {
            display: none;
        }
    }
}

.explore_more_section {
    background: $black;
    padding: 60px 0;

    // position: relative;
    h3 {
        font-size: 36px;
        font-weight: 500;
        line-height: 44px;
        letter-spacing: -0.02em;
        color: $white300;
        margin: 0;
        margin-bottom: 16px;
    }

    h5 {
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        color: $white300;
        width: 70%;
        margin: 0;
    }

    .exploreMore-Card {
        position: relative;
        margin-top: 60px;

        .tabs_section {
            position: absolute;
            display: flex;
            gap: 1.25rem;
            width: max-content;
            color: $white;
            margin-left: 40px;
            border: 1px solid #212b2e;
            border-radius: 6px;
            padding: 6px;
            background: $black;
            top: -8%;
            z-index: 2;

            .tabs_container {
                font-size: 18px;
                font-weight: 400;
                line-height: 28px;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;
                padding: 8px 8px;
                border-radius: 8px;
                cursor: pointer;
                transition: all 100ms;
            }

            .active_tab {
                background: $green500;
                font-size: 20px;
                font-weight: 600;
                line-height: 30px;
                color: $green1000;
            }
        }
    }

    .select_container {
        display: none;
    }
}

.card_group_wrapper {
    background: #121212;
    padding: 60px 0;
    padding-bottom: 90px;

    .card_group_heading {
        font-size: 36px;
        line-height: 44px;
        font-weight: 500;
        letter-spacing: -0.02;
        text-align: center;
        color: $white300;
    }

    .card_group {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .card_group3X {
        justify-content: center;
        gap: 24px;

        .card_group_item3X {
            width: calc(33% - 22px);
            justify-content: unset !important;
            gap: 24px;

            .card_container {
                width: 100%;
                height: 100%;
                background: $black;
                border-radius: 8px;
                gap: 24px;
                border: 1px solid $black500;

                .card_item {
                    padding: 24px 32px;
                    height: 100%;

                    .card_itemV {
                        display: flex;
                        flex-direction: row;
                        gap: 20px;
                    }

                    .card_title {
                        font-size: 20px;
                        font-weight: 500;
                        line-height: 30px;
                        color: $green500;
                    }

                    .card_text {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        color: $white400;
                    }
                }
            }
        }
    }
}

.integration_block_feature {
    background: var(--Icon, #0f2422);
    // padding: 30px 0;

    .integration_block_container {
        display: flex;
        justify-content: space-between;

        .integration_content {
            width: calc(50% - 34px);
            font-weight: 500;
            font-size: 30px;
            line-height: 38px;
            color: $white300;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .integration_tools {
            width: calc(50% - 33px);
            max-height: 266px;
            display: flex;
            gap: 20px;
            flex-direction: column;
            flex-wrap: wrap;
            // align-items: center;
            position: relative;

            .group_icon {
                width: 120px;
                height: 120px;
                border-radius: 10px;
                background: #f2f4f7e5;
                border: 1px solid #667085;
                position: relative;

                .data_icon {
                    position: absolute;
                    max-width: 67px;
                    height: auto;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .group_icon:nth-child(1),
            .group_icon:nth-child(2),
            .group_icon:nth-child(5),
            .group_icon:nth-child(6) {
                top: -8px;
            }

            .group_icon:nth-child(3),
            .group_icon:nth-child(4) {
                top: 40px;
            }

            .group_icon:nth-child(7),
            .group_icon:nth-child(8) {
                top: -40px;
            }
        }
    }
}

.data_security_section {
    background: #121212;
    padding: 120px 0;

    .data_security_container {
        text-align: center;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
            radial-gradient(97.57% 210.75% at 0.9% 2.98%, rgba(0, 0, 0, 0.28) 0%, rgba(255, 255, 255, 0) 100%);
        border: 1px solid $black500;

        .data_security_heading {
            font-size: 48px;
            font-weight: 500;
            line-height: 60px;
            color: $white300;
        }

        .data_security_content {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            .security_logoText {
                display: flex;
                flex-direction: column;
                margin: 10px 0;

                .security_logo {
                    padding: 0 90px;
                }

                .logoText {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    color: $white300;
                    margin-top: 10px;
                }
            }
        }
    }

    .data_security_content>.security_logoText:nth-child(1) .security_logo,
    .data_security_content>.security_logoText:nth-child(2) .security_logo {
        border-right: 2px solid #ffffff14;
    }
}

@media (max-width: 768px) {
    .hero_section_content {
        padding: 90px 10px 40px 10px;

        h3 {
            font-size: 30px;
            line-height: 38px;
        }

        h5 {
            font-size: 14px;
            line-height: 20px;
        }

        .MuiButton-root {
            padding: 10px 18px;
            margin-bottom: 100px;
        }
    }

    .hero_section_content_bg {
        .hero_section_content_bg_2 {
            margin-top: 18px;
        }

    }

    .features_section {
        padding: 32px 10px;

        .feature_container {
            flex-direction: column;

            .desktop_feature_content {
                display: none;
            }

            .mobile_feature_content {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 32px;

                .feature_title {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    color: $green500;

                    .accordian_header_number {
                        font-size: 14px;
                        font-weight: 600;
                        border: 1px solid #04b2a4;
                        border-radius: 50%;
                        height: 24px;
                        width: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .accordian_header_title {
                        font-size: 20px;
                        line-height: 30px;
                        font-weight: 500;
                    }
                }

                .feature_subText {
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 400;
                    color: $white300;
                }

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    @media (max-width: 768px) {
        .explore_more_section {
            padding: 60px 0;

            h3 {
                font-size: 30px;
                font-weight: 600;
                line-height: 38px;
            }

            h5 {
                font-size: 14px;
                line-height: 20px;
                width: 100%;
                margin-bottom: 32px;
            }

            .explore_more_container {
                display: flex;
                flex-direction: column;

                #select-wrapper {
                    display: flex;
                    flex-direction: column-reverse;

                    .select_container {
                        display: flex;
                        width: 100%;
                        height: 72px;
                        margin: 16px 0;
                    }

                    .exploreMore-Card {
                        margin-top: 0;

                        .tabs_section {
                            display: none;
                        }
                    }
                }
            }

            .custom_select {
                color: #ffffff;
                border: 1px solid #1d2939;

                .MuiSelect-icon {
                    color: $green500;
                }

                .MuiOutlinedInput-notchedOutline {
                    border-color: #1d2939;
                }

                &:focus .MuiOutlinedInput-notchedOutline {
                    border-color: $green500;
                }
            }
        }
    }

    .card_group_wrapper {
        padding: 32px 0;
        padding-bottom: 60px;

        .card_group_heading {
            font-size: 30px;
            font-weight: 600;
            line-height: 38px;
            //text-align: start;
            text-align: center;
        }

        .card_group {
            flex-direction: column;
        }

        .card_group3X {
            justify-content: center;
            gap: 24px;

            .card_group_item3X {
                width: 100%;

                .card_container {
                    border-radius: 12px;
                }
            }
        }
    }

    .integration_block_feature {
        padding-top: 30px;

        .integration_block_container {
            flex-direction: column;
            gap: 60px;

            .integration_content {
                width: 100%;
                font-size: 20px;
                line-height: 30px;
                font-weight: 500;
            }

            .integration_tools {
                width: 100%;
                height: 200px;
                gap: 10px;

                .group_icon {
                    width: 70px;
                    height: 70px;

                    .data_icon {
                        max-width: 38px;
                    }
                }

                .group_icon:nth-child(3),
                .group_icon:nth-child(4) {
                    top: 18px;
                }

                .group_icon:nth-child(7),
                .group_icon:nth-child(8) {
                    top: -32px;
                }
            }
        }
    }

    .data_security_section {
        padding: 60px 0;
        padding-bottom: 0;

        .data_security_container {
            .data_security_heading {
                font-size: 30px;
                line-height: 38px;
            }

            .data_security_content {
                width: 100%;
                flex-wrap: wrap;

                .security_logoText {
                    .security_logo {
                        padding: 0 32px;
                    }

                    .logoText {
                        font-size: 12px;
                        line-height: 18px;
                    }
                }
            }
        }

        .data_security_content>.security_logoText:nth-child(2) .security_logo {
            border-right: none;
        }
    }
}