.mainAppWrapper {
    // padding-top: 82px;
    overflow: hidden;
    .header {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 999;
    }
}
