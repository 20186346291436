@import '../../sass/variable';
@import '../../sass/functions';
@import '../../sass/responsiveWidthInfo';

main {
    .mainContent {
        display: flex;
        justify-content: space-between;
        background: $black500;

        &>div {
            float: left;
            width: 30%;
            height: 100%;
            background: $black500;
            padding-top: 170px;

            h1 {
                text-align: right;
                background: linear-gradient(90deg, rgba(4, 178, 164, 0.15) 0%, rgba(4, 178, 164, 0) 94.46%);
                color: $white;
                font-size: 30px;
                font-weight: 600;
                line-height: 38px;
                padding: 44px 24px 44px 40%;
            }
        }

        .success {
            float: left;
            width: 70%;
            height: 90vh;
            background: $white200;
            padding: 90px 60px;
            text-align: center;
            color: $siteTextColor;
            display: flex;
            justify-content: center; 
            align-items: center; 
            flex-direction: column; 

            .checkOuter {
                width: 200px;
                height: 200px;
                border-radius: 50%;
                position: relative;
                background: $green700;
                display: inline-block;

                &>div {
                    width: 136px;
                    height: 136px;
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background: $green800;
                    display: inline-block;

                    &>svg {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        font-size: 110px;
                        color: $white;
                    }
                }
            }

            h2 {
                font-size: 24px;
                font-weight: 600;
                line-height: 32px;
                margin: 16px 0 8px;
            }

            p {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                margin: 0;
            }
        }

        form {
            float: left;
            width: 70%;
            height: 100%;
            background: $white200;
            padding: 0 120px 42px 120px;

            .MuiButton-root {
                line-height: 46px;
                border-color: $black200;
                background: $black200;
                color: $white;

                &:hover {
                    background: $black200;
                }
            }

            h2 {
                color: $siteTextColor;
                text-align: center;
                font-size: 30px;
                font-weight: 600;
                line-height: 38px;
                margin: 70px 0 48px;
            }

            .inputWrap {
                margin-bottom: 32px;
                float: left;
                width: 50%;

                &:nth-child(2n) {
                    padding-right: 16px;
                }

                &:nth-child(2n + 1) {
                    padding-left: 16px;
                }

                &:last-of-type {
                    width: 100%;
                    padding: 0;
                }

                &.errorWrap {
                    .MuiInputBase-root {
                        border: 1px solid $red100;
                    }
                }

                .warning {
                    margin-top: 8px;
                    color: $red100;
                    font-weight: 500;
                }

                label {
                    display: block;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    margin-bottom: 12px;
                }

                .MuiInputBase-root {
                    border-radius: 8px;
                    border: 1px solid $grey300;
                    background: $white;
                    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);

                    input {
                        height: inherit;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        padding: 10px 14px;
                    }

                    fieldset {
                        display: none;
                    }

                }

                input[type="number"]::-webkit-outer-spin-button,
                input[type="number"]::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
            }
        }
    }
}

@media (max-width: $breakpointIpadPortrait) {
    body {
        main {
            .mainContent {
                &>div {
                    display: none;
                }

                .success {
                    width: 100%;

                    .checkOuter {
                        width: 100px;
                        height: 100px;

                        &>div {
                            width: 70px;
                            height: 70px;

                            svg {
                                font-size: 40px;
                            }
                        }
                    }

                    h2 {
                        font-size: 24px;
                        line-height: 32px;
                        margin: 16px 0;
                    }
                }

                form {
                    width: 100%;
                    height: auto;
                    padding: 0 32px 40px;

                    h2 {
                        font-size: 24px;
                        line-height: 32px;
                        margin: 40px 0 32px;
                    }

                    .inputWrap {
                        width: 100%;
                        padding: 0 !important;
                        margin-bottom: 24px;
                    }
                }
            }
        }
    }
}