.client-list-section {
    padding: 32px 0;
    background: #f8ffff;
    .image-carousel {
        display: flex;
        width: 100%;
        overflow: hidden;
        user-select: none;
        mask-image: linear-gradient(
            to right,
            hsl(0 0% 0% / 0),
            hsl(0 0% 0% / 1) 10%,
            hsl(0 0% 0% / 1) 90%,
            hsl(0 0% 0% / 0)
        );

        .carousel-group,
        .carousel-group2 {
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 42px;
            white-space: nowrap;
            animation: scrollX 30s linear infinite;

            .image-group {
                display: grid;
                place-items: center;
                padding: calc(clamp(10rem, 1rem + 30vmin, 30rem) / 10);

                .carousel-image {
                    object-fit: contain;
                    border-radius: 8px;
                }
            }
        }

        .carousel-group2 {
            animation-direction: reverse;
            animation-delay: -3s;
        }
    }
    .bannerMobileList {
        display: none;
    }
}

@keyframes scrollX {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

@media (max-width: 768px) {
    .client-list-section {
        .image-carousel {
            display: none;
        }
        .bannerMobileList {
            margin-bottom: 60px;
            gap: 21px 0;
            height: 85px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

            .imageMobileWrapper {
                width: 33%;
                height: 42px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
