@import '../../sass/variable';

.hero_sect_wrapper {
    background: #121212;
    background-image: url('../../assets/industries/heroBanner.png');
    background-size: cover;
    padding: 100px 0 0 0;

    .hero_sect {
        display: flex;
        flex-direction: column;
        gap: 100px;

        .hero_sect_row1 {
            display: flex;
            width: 100%;

            .hero_sect_columnL {
                flex: 1;
                display: flex;
                flex-direction: column;

                .hero_sect_title {
                    font-size: 54px;
                    line-height: 72px;
                    letter-spacing: -0.02;
                    font-weight: 500;
                    margin-bottom: 0;
                    color: #Fcfcfd;
                }

                .hero_sect_body {
                    font-size: 16px;
                    line-height: 30px;
                    font-weight: 400;
                    color: $white300;
                    width: 65%;
                }

                .link_style {
                    color: white;
                    font-size: 16px;
                    line-height: 30px;
                    font-weight: 400;
                    margin-top: -25px;
                    text-align: left;
                    span {
                        color: #038e83;
                    }   
                }
                .link_style a {
                    text-decoration: none; 
                }

                .hero_sect_subtitle {
                    font-size: 12px;
                    line-height: 30px;
                    font-weight: 400;
                    color: #fcfcfd;
                    margin-top: 10px;
                }
            }

            .hero_sect_columnR {
                display: flex;
                align-items: center;
            }
        }

        .hero_sect_row2 {
            .hero_paragraphh {
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
                color: #F9FAFB;
                margin-bottom: 20px;
                text-align: left;
            }
        }
    }
}

.datasect_wrapper {
    background-size: cover;
    overflow: hidden;
    color: white;
    padding: 100px 0 0 0;

    .data_sect {
        .section-block {
            margin-bottom: 80px;
            padding: 0 20px;
        }

        .terms-title {
            font-size: 36px;
            line-height: 44px;
            font-weight: 600;
            color: #F9FAFB;
            margin-bottom: 80px;
        }

        h2 {
            font-size: 36px;
            line-height: 44px;
            font-weight: 500;
            color: #FCFCFD;
            text-align: left;
            margin-bottom: 30px;
        }

        p {
            margin: 10px 0;
            line-height: 24px;
            font-size: 16px;
            font-weight: 400;
            color: #f9fafb;
            text-align: left;
            white-space: pre-wrap;
        }

        ul {
            margin: 10px 0;
            padding-left: 20px;
            color: #f9fafb;
            list-style-type: disc;
        }

        li {
            margin-bottom: 5px;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            text-align: left;
        }
    }
}

@media (max-width: 768px) {
    .hero_sect_wrapper {
        padding: 100px 0 20px 10px;

        .hero_sect {
            gap: 40px;

            .hero_sect_row1 {
                flex-direction: column;

                .hero_sect_columnL {
                    .hero_sect_title {
                        font-size: 28px;
                        line-height: 38px;
                        margin-bottom: 10px;
                        margin-top: 20px;
                        font-weight: 500;
                    }

                    .hero_sect_body {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }

                .hero_sect_columnR {
                    display: none;
                }
            }

            .hero_sect_row2 {
                .hero_paragraphh {
                    font-size: 16px;
                    line-height: 24px;
                    margin-bottom: 10px;
                    text-align: left;
                }
            }
        }
    }

    .datasect_wrapper {
        padding: 50px 20px 0 10px;

        .data_sect {
            .section-block {
                margin-bottom: 40px;
                padding: 0px;
            }

            .terms-title {
                font-size: 28px;
                line-height: 38px;
                font-weight: 500;
            }

            h2 {
                font-size: 24px;
                line-height: 32px;
                margin-bottom: 30px;
                margin-top: 70px;
            }

            p {
                margin: 8px 0;
                line-height: 24px;
                font-size: 16px;
                letter-spacing: -0.02;
            }

            ul {
                margin: 8px 0;
            }

            li {
                margin-bottom: 4px;
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
}