.footerContainer {
    margin: 0 auto;
    padding-top: 51px;
    background: #021211;

    .footer {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        gap: 32px;
        padding: 20px 0;

        .footer-section {
            min-width: 200px;
            color: #ffffff;

            h5 {
                margin-bottom: 15px;
                font-size: 18px;
                line-height: 28px;
                font-weight: 700;
            }

            p {
                margin-bottom: 15px;
                font-size: 18px;
                line-height: 28px;
                font-weight: 700;
            }

            ul {
                list-style: none;
                padding: 0;
                margin-bottom: 32px;

                li {
                    margin-bottom: 10px;
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 700;

                    a {
                        color: #ffffff;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .footer-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 28px 0;
        border-top: 1px solid #d6d8de;
        gap: 8px;

        .footer-copyright {
            color: #ffffff;
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
        }

        .footer-privacycopyright {
            font-size: 12px;
            line-height: 18px;
            font-weight: 400;
            color: #d0d5dd;

            span {
                border-left: 1px solid #475467;
                margin: 8px;
            }
        }
    }

    .mobileFooter {
        display: none;
        width: 100%;
    }

    .footerButton {
        border: none;
        border-radius: 16px;
        background: rgba(238, 251, 250, 0.1);
        color: #ffffff;
        margin-left: 10px;
        font-weight: 700;
        font-size: 12px;
        padding: 2px 8px;
        line-height: 18px;
    }

    .footerContents {
        display: flex;
    }

    .desktop-footer-icons {
        display: flex;
        gap: 16px;
        margin-top: 16px;
    }

    .mobile-footer-icons {
        display: none;
    }

    .footer-link {
        color:  #d0d5dd; 
        text-decoration: none; 
    }
    
    .footer-link:hover {
        text-decoration: none; 
    }

    @media (max-width: 768px) {
        .footerContainer {
            .footer {
                justify-content: space-around;

                .footer-section {
                    flex-basis: 48%;
                }
            }

            .footer-copyright {
                display: block;
            }

            .footer-text {
                display: none;
            }

            // .desktop-footer-icons {
            //     display: flex;
            //     justify-content: center;
            //     align-items: center;
            // }
        }
    }

    @media (max-width: 480px) {
        // .footerContainer{

        .footerContents {
            display: none;
        }

        .footer-section {
            flex-basis: 100%;

            h5 {
                padding: 10px 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 16px;
                line-height: 24px;
                margin: 0;
                border-bottom: 1px solid #d0d5dd33;
                color: #fff;
            }
            ul {
                padding: 10px 20px;
                margin-bottom: 0;
                
                li {
                    font-size: 16px;
                    line-height: 24px;

                    a {
                        color: #ffffff;
                        text-decoration: none;
                    }

                    padding-bottom: 6px;
                }

                .desktop-footer-icons {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .footer-logo {
            border-top: 1px solid #d0d5dd33;

            .footer-copyright {
                color: #ffffff;
                font-size: 12px;
                font-weight: 400;
            }
        }

        .mobileFooter {
            display: block;
        }

        // .mobile-footer-icons {
        //     display: flex;
        //     text-align: center;
        //     justify-content: center;
        //     gap: 16px;
        //     border-top: 1px solid #d0d5dd33;
        //     padding: 24px 0;
        // }

        // .footer-copyright {
        //     color: #ffffff;
        //     font-size: 12px;
        //     font-weight: 400;
        // }

        // .desktop-footer-icons {
        //     display: none;
        // }
    }
}