// Colors
$siteTextColor: #1d2939;

$white: #ffffff;
$white100: #f7ffff;
$white200: #f8ffff;
$white300: #FCFCFD;
$white400: #F9FAFB;
$white500: #9fa2a7;

$black: #000000;
$black100: #0f2642;
$black200: #072c28;
$black300: #344054;
$black400: #0e2642;
$black500: #0b192a;
$black600: #121212;
$black700: #111918;
$black800: #101621;
$black900: #111b25;

$grey100: #f2f2f2;
$grey200: #98a2b3;
$grey300: #d0d5dd;
$grey400: #475467;

$red100: #d92d20;

$yellow100: #d88f00;

$orange100: #f79009;

$blue100: #0679c3;
$blue200: #3a8a8a;

$green100: #28ebc4;
$green200: #36c1b6;
$green300: #038e83;
$green400: #eefbfa;
$green500: #04b2a4;
$green600: #cdf0ed;
$green700: #d1fadf;
$green800: #12b76a;
$green900: #02474299;
$green1000: #024742;
$green1100: #026B62;
$green1200: #00D6C4;
$green1300: #142A2A;